import {  useRef, useEffect } from 'react';
import { Progress } from '@/components/ui/progress';
import { Button } from '@/components/ui/button';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Questions } from './Components/Questions';
import { CategoryDetails } from './Components/CategoryDetails';
import { useQuestionDetails } from './useQuestionDetails';
import PatientLayout from '@/layouts/Patient/PatientLayout';
import withLayout from '@/hoc/withLayout';
import { Link } from 'react-router-dom';
import {  Stethoscope } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { LOADING_LOGO_URL } from '@/constants';

function QuestionDetails() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    assessment,
    progress,
    isLoading,
    question,
    handleNextQuestion,
    handlePreviousQuestion,
    handleAnswerSubmit,
    setAnswers,
    handleCompleteAssessment,
    answers,
    isSubmitting,
    isNavigating,
    showConfirmDialog,
    setShowConfirmDialog,
    confirmCompleteAssessment,
    isSubmittingAssessment,
  } = useQuestionDetails();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [question]);

  if (isLoading) {
    return (
      <div className="container mx-auto min-h-[calc(100vh-200px)] flex flex-col items-center justify-center px-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!assessment || !question) {
    return (
      <div className="container mx-auto min-h-[calc(100vh-200px)] flex flex-col items-center justify-center px-4">
        <div className="w-full max-w-md space-y-4 text-center">
          <div className="flex flex-col items-center justify-center space-y-4">
            <Stethoscope className="h-12 w-12 text-primary" />
            <h2 className="text-2xl font-bold tracking-tight">No questions found</h2>
          </div>
          <p className="text-muted-foreground">
            It looks like there are no questions available for this assessment.
          </p>
          <Link to="/" className="mt-6 block">
            <Button variant="outline" size="lg" className="w-full sm:w-auto">
              Go to Home
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  const handleAnswer = (questionId: string, value: string) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));
    handleAnswerSubmit(question.id, value);
  };

  const isFirstQuestion = progress?.currentQuestionNumber === 1;
  const isLastQuestion = progress?.currentQuestionNumber === progress?.totalQuestions;
  return (
    <div className="container mx-auto p-4 mt-8 " ref={scrollRef}>
      {(isLoading || isSubmitting || isNavigating || isSubmittingAssessment) && (
        <div className="fixed inset-0 z-40 bg-[var(--background)]">
          <div className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] sm:max-w-md w-full">
            <div className="bg-[var(--background)]   p-6">
              <div className="flex flex-col items-center justify-center space-y-4">
                <img src={LOADING_LOGO_URL} alt="Spinner" className="w-20 h-20" />
                <h2 className="text-xl font-semibold">
                  {isSubmittingAssessment
                    ? "Submitting Assessment"
                    : isSubmitting
                      ? "Submitting Answer"
                      : "Loading Question"}
                </h2>
                <p className="text-center text-muted-foreground">
                  {isSubmittingAssessment
                    ? "Please wait while we submit your assessment..."
                    : isSubmitting
                      ? "Please wait while we submit your answer"
                      : "Please wait while we load the question"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mb-4">

        <Progress
          value={progress?.progressPercentage}
          className="w-full"
          indicatorClassName="bg-[var(--primary)]"
        />


        <p className="text-sm text-muted-foreground mt-2">
          Progress: {Math.round(progress?.progressPercentage || 0)}%
        </p>
      </div>

      <div className={isMobile ? "flex flex-col space-y-4" : "grid grid-cols-2 gap-6 h-[calc(100vh-200px)]"}>
        <div className={isMobile ? "w-full" : ""}>
          <CategoryDetails
            title={assessment.testId.name}
            description={assessment.testId.description}
            isActive={true}
            section={question.section}
          />
        </div>

        <div className={isMobile ? "w-full" : ""}>
          <Questions
            question={question}
            answers={answers}
            onAnswer={handleAnswer}
            onPrevious={handlePreviousQuestion}
            onNext={handleNextQuestion}
            isFirstQuestion={isFirstQuestion}
            isLastQuestion={isLastQuestion}
            completeAssessment={handleCompleteAssessment}
            totalQuestions={progress?.totalQuestions || 0}
            currentQuestionNumber={progress?.currentQuestionNumber || 0}
          />
        </div>
      </div>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Complete Assessment</DialogTitle>
            <DialogDescription>
              Are you sure you want to submit this assessment? You won't be able to change your answers after submission.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex gap-2">
            <Button
              variant="outline"
              onClick={() => setShowConfirmDialog(false)}
            >
              Cancel
            </Button>
            <Button
             variant="orange"
              onClick={confirmCompleteAssessment}
            >
              Submit Assessment
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withLayout(PatientLayout)(QuestionDetails);
