const CONSTANTS = {
    LOADING_LOGO_URL:"https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6743fb8f8ec9347abaf8b426/data/media/images/3dgifmaker46755.gif",
    LOG_IN_URL:"https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6743fb8f8ec9347abaf8b426/data/media/images/LOGIN_IMAGE.jpg",
    CIRCLE_LOGO_URL:"https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6752b915ccf44e3f8261d762/data/media/images/vedita-ai-circle-logo.svg",
    LOGO_URL: "https://vedita-ai.s3.ap-south-1.amazonaws.com/prod/675a40133570563dc4fc6639/data/media/images/VeditaLogo.png",
    NEW_HERO_IMAGE_URL: "https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6743fb8f8ec9347abaf8b426/data/media/images/DeWatermark.ai_1733835067635.png",
    HERO_IMAGE_URL: "https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6752b915ccf44e3f8261d762/data/media/images/Untitled-transformed.jpeg",
    FEATURES_IMAGE_URL: "https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6743fb8f8ec9347abaf8b426/data/media/images/dbR5qwF2lahEF7geL6gpU.jpg",
    AYURVEDIC_HEALTH_IMAGE_URL: "https://vedita-ai.s3.ap-south-1.amazonaws.com/dev/6743fb8f8ec9347abaf8b426/data/media/images/6W7QonMd9fBddNp8enzPd.jpg"
} as const;

export const {LOGO_URL,LOG_IN_URL, HERO_IMAGE_URL, FEATURES_IMAGE_URL,AYURVEDIC_HEALTH_IMAGE_URL ,CIRCLE_LOGO_URL,NEW_HERO_IMAGE_URL,LOADING_LOGO_URL} = CONSTANTS;

export default CONSTANTS;

export const tooltipStyles = "bg-gray-800 text-white border border-gray-700 shadow-lg p-2 rounded-md";
