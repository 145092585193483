import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { IndianRupee, Lock, Unlock, CheckCircle2, FileX, Plus } from 'lucide-react'
import { useTests } from './useTests'
import PatientLayout from '@/layouts/Patient/PatientLayout'
import withLayout from '@/hoc/withLayout'
import { useMediaQuery } from '@/hooks/use-media-query'
import cn from 'classnames';
import ReactPaginate from 'react-paginate';
import { Skeleton } from "@/components/ui/skeleton"
import { OrderSelfTest} from "@/components/patient/OrderTest/SelfOrderTest"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Test } from "@/types/patient/test"
import { tooltipStyles } from "@/constants";

function TestsPage() {
  const {
    tests,
    handleViewTest,
    currentPage,
    setCurrentPage,
    pageSize,
    isLoading,
    ordersData,
    isTestModalOpen,
    setIsTestModalOpen,
    testTypes,
    handleCreateTest,
    isLoadingTestTypes

  } = useTests()

  const pagination = ordersData?.pagination;

  const isMobile = useMediaQuery("(max-width: 640px)")
  const isTablet = useMediaQuery("(min-width: 641px) and (max-width: 1024px)")


  const formatTestNames = (tests: string[]) => {
    if (tests.length === 0) return '';
    return tests[0];
  };

  const getExtraTestsCount = (tests: string[]) => {
    return tests.length > 1 ? tests.length - 1 : 0;
  };

  
  return (
    <div className="container mx-auto py-6 space-y-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="text-xl font-bold">My Tests</CardTitle>
          <Dialog open={isTestModalOpen} onOpenChange={setIsTestModalOpen}>
            <DialogTrigger asChild>
              <Button variant="orange">
                <Plus className="h-4 w-4 mr-2" />
                Order Tests
              </Button>
            </DialogTrigger>  
            <DialogContent 
              onPointerDownOutside={(e) => {
                e.preventDefault();
              }}
            >
              <DialogHeader>
                <DialogTitle>Create New Test</DialogTitle>
              <OrderSelfTest
               onSubmit={handleCreateTest} 
               isLoading={isLoadingTestTypes}
               testTypes={testTypes}
               isLoadingTestTypes={isLoadingTestTypes}
               />
              </DialogHeader>
             
            </DialogContent>
          </Dialog>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="space-y-2">
              {[...Array(10)].map((_, index) => (
                <Skeleton key={index} className="w-full h-8" />
              ))}
            </div>
          ) : !tests?.length ? (
            <div className="flex flex-col items-center justify-center py-8 text-center">
              <div className="rounded-full bg-muted p-3">
                <FileX className="h-6 w-6 text-muted-foreground" />
              </div>
              <h3 className="mt-4 text-lg font-semibold">No Tests Found</h3>
              <p className="mt-2 text-sm text-muted-foreground">
                No tests have been ordered yet. Click the "Order Test" button to create a new test.
              </p>
            </div>
          ) : (
            <div>
              {isMobile ? (
                // Mobile View
                <div className="space-y-4">
                  {tests.map((test: Test) => (
                    <Card key={test.id} className={`p-4 ${test.status === 'PAYMENT_COMPLETED' ? "bg-blue-50/50" : ""}`}>
                      <div className="flex flex-col space-y-3">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <div>
                              {test.status === 'PAYMENT_PENDING' ? (
                                <Lock className="h-4 w-4 text-red-500" />
                              ) : (
                                <Unlock className="h-4 w-4 text-green-500" />
                              )}
                            </div>
                            <div>
                              {test.tests.length > 1 ? (
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <span className="font-medium">
                                        {formatTestNames(test.tests)}
                                        <span className="font-bold"> +{getExtraTestsCount(test.tests)}</span>
                                      </span>
                                    </TooltipTrigger>
                                    <TooltipContent className={tooltipStyles} side="top">
                                      <p>{test.tests.join(', ')}</p>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              ) : (
                                <span className="font-medium">{formatTestNames(test.tests)}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="text-sm text-muted-foreground">
                          <div>Doctor: {test.addedByDr}</div>
                          <div>Date: {test.orderedAt}</div>
                        </div>
                        <div className="flex flex-col space-y-3">
                          <div className="flex items-center justify-between">
                            <Badge
                              variant="secondary"
                              className={cn("w-fit", {
                                "bg-orange-100 text-orange-700 hover:bg-orange-100": test.status === 'PAYMENT_PENDING',
                                "bg-green-100 text-green-700 hover:bg-green-100": test.status === 'PAYMENT_COMPLETED',
                                "bg-gray-100 text-gray-700 hover:bg-gray-100": test.status === 'TEST_COMPLETED'
                              })}
                            >
                              {test.status === 'TEST_COMPLETED' && <CheckCircle2 className="h-3 w-3 mr-1" />}
                              {test.status === 'TEST_COMPLETED' ? 'Completed' :
                                test.status === 'PAYMENT_COMPLETED' ? 'Ready to Start' : 'Payment Pending'}
                            </Badge>
                            {test.amount > 0 && (
                              <div className="flex items-center gap-1">
                                <IndianRupee className="h-4 w-4" />
                                <span>{test.amount}</span>
                              </div>
                            )}
                          </div>
                          <Button
                            variant="orange"
                            size="sm"
                            className="w-full"
                            onClick={() => handleViewTest(test)}
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                  {pagination && pagination.totalPages > 1 && (
                    <div className="flex justify-center mt-4">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageCount={pagination.totalPages}
                        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                        forcePage={currentPage - 1}
                        containerClassName="flex gap-2 items-center"
                        previousClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                        nextClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                        pageClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        activeClassName="!bg-[var(--primary)] text-white shadow-sm hover:bg-[var(--primary-hover)]"
                        breakLabel="..."
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                      />
                    </div>
                  )}
                </div>
              ) : isTablet ? (
                // Tablet View
                <div className="space-y-4">
                  {tests.map((test: Test) => (
                    <Card key={test.id} className={`p-4  hover:shadow-md transition-all ${test.status === 'PAYMENT_COMPLETED' ? "bg-blue-50/50" : ""}`} >
                      <div className="flex flex-col space-y-3">
                        <div className="flex justify-between items-start">
                          <div className="space-y-2">
                            <div className="flex items-center gap-2">
                              {test.status === 'PAYMENT_PENDING' ? (
                                <Lock className="h-4 w-4 text-red-500" />
                              ) : (
                                <Unlock className="h-4 w-4 text-green-500" />
                              )}
                              {test.tests.length > 1 ? (
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <span className="font-medium">
                                        {formatTestNames(test.tests)}
                                        <span className="font-bold"> +{getExtraTestsCount(test.tests)}</span>
                                      </span>
                                    </TooltipTrigger>
                                    <TooltipContent className={tooltipStyles} side="top">
                                      <p>{test.tests.join(', ')}</p>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              ) : (
                                <span className="font-medium">{formatTestNames(test.tests)}</span>
                              )}
                            </div>
                            <div className="text-sm text-muted-foreground">
                              <div>Doctor: {test.addedByDr}</div>
                              <div>Date: {test.orderedAt}</div>
                            </div>
                          </div>
                          <div className="flex flex-col items-end gap-2">
                            <Badge
                              variant="secondary"
                              className={cn({
                                "bg-orange-100 text-orange-700 hover:bg-orange-100": test.status === 'PAYMENT_PENDING',
                                "bg-green-100 text-green-700 hover:bg-green-100": test.status === 'PAYMENT_COMPLETED',
                                "bg-gray-100 text-gray-700 hover:bg-gray-100": test.status === 'TEST_COMPLETED'
                              })}
                            >
                              {test.status === 'TEST_COMPLETED' && <CheckCircle2 className="h-3 w-3 mr-1" />}
                              {test.status === 'TEST_COMPLETED' ? 'Completed' :
                                test.status === 'PAYMENT_COMPLETED' ? 'Ready to Start' : 'Payment Pending'}
                            </Badge>
                            {test.amount > 0 && (
                              <div className="flex items-center gap-1">
                                <IndianRupee className="h-4 w-4" />
                                <span>{test.amount}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-end pt-2 border-t">
                          <Button
                            variant="orange"
                            size="sm"
                            onClick={() => handleViewTest(test)}
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                  {pagination && pagination.totalPages > 1 && (
                    <div className="flex justify-center mt-4">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageCount={pagination.totalPages}
                        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                        forcePage={currentPage - 1}
                        containerClassName="flex gap-2 items-center"
                        previousClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                        nextClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                        pageClassName="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        activeClassName="!bg-[var(--primary)] text-white shadow-sm hover:bg-[var(--primary-hover)]"
                        breakLabel="..."
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                      />
                    </div>
                  )}
                </div>
              ) : (
                // Desktop View
                <div className="overflow-x-auto">
                  <Table>

                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[100px]">S No.</TableHead>
                        <TableHead>Tests</TableHead>
                        <TableHead>Doctor</TableHead>

                        <TableHead>Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>T. Amount</TableHead>
                        <TableHead className="text-right">Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {tests.map((test: Test) => (
                        <TableRow key={test.id} className={test.status === 'PAYMENT_COMPLETED' ? "bg-blue-50/50" : ""}>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <span className="font-medium">
                                {((currentPage - 1) * pageSize) + tests.indexOf(test) + 1}
                              </span>
                              {test.status === 'PAYMENT_PENDING' ? (
                                <Lock className="h-4 w-4 text-red-500" />
                              ) : (
                                <Unlock className="h-4 w-4 text-green-500" />
                              )}
                            </div>
                          </TableCell>


                          <TableCell>
                            {test.tests.length > 1 ? (
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <span>
                                      {formatTestNames(test.tests)}
                                      <span className="font-bold"> +{getExtraTestsCount(test.tests)}</span>
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipContent className={tooltipStyles} side="top">
                                    <p>{test.tests.join(', ')}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            ) : (
                              <span>{formatTestNames(test.tests)}</span>
                            )}
                          </TableCell>
                          <TableCell className="font-medium">{test.addedByDr}</TableCell>
                          <TableCell>{test.orderedAt}</TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <Badge
                                variant="secondary"
                                className={cn({
                                  "bg-orange-100 text-orange-700 hover:bg-orange-100": test.status === 'PAYMENT_PENDING',
                                  "bg-green-100 text-green-700 hover:bg-green-100": test.status === 'PAYMENT_COMPLETED',
                                  "bg-gray-100 text-gray-700 hover:bg-gray-100": test.status === 'TEST_COMPLETED'
                                })}
                              >
                                {test.status === 'TEST_COMPLETED' && <CheckCircle2 className="h-3 w-3 mr-1" />}
                                {test.status === 'TEST_COMPLETED' ? 'Completed' :
                                  test.status === 'PAYMENT_COMPLETED' ? 'Ready to Start' : 'Payment Pending'}
                              </Badge>
                            </div>
                          </TableCell>
                          <TableCell>
                            {test.amount ? (
                              <div className="flex items-center gap-1">
                                <IndianRupee className="h-4 w-4" />
                                <span>{test.amount}</span>
                              </div>
                            ) : (
                              <span>-</span>
                            )}
                          </TableCell>
                          <TableCell className="text-right">
                            <Button
                              variant="orange"
                              size="sm"
                              className="px-6"
                              onClick={() => handleViewTest(test)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {pagination && pagination.totalPages > 1 && (
                    <div className="flex justify-center mt-4">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageCount={pagination.totalPages}
                        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                        forcePage={currentPage - 1}
                        containerClassName="flex gap-2 items-center"
                        previousClassName="px-3 py-1 rounded bg-gray-100 hover:bg-gray-200"
                        nextClassName="px-3 py-1 rounded bg-gray-100 hover:bg-gray-200"
                        pageClassName="px-3 py-1 rounded bg-gray-100 hover:bg-gray-200"
                        activeClassName="!bg-[var(--primary)] text-white shadow-sm hover:bg-[#FF8A4D]"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        breakLabel="..."
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

        </CardContent>
      </Card>
    </div>
  )
}

export default withLayout(PatientLayout)(TestsPage)