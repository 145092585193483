import React, { useState, useMemo, useEffect } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Search, ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { useBookReader } from '@/contexts/BookReaderContext';

export function Outline() {
  const {
    tableOfContents,
    isLoadingToc,
    handlePageSelect,
    currentPage,
  } = useBookReader();

  const [searchQuery, setSearchQuery] = useState('');
  const [expandedSections, setExpandedSections] = useState<number[]>([]);
  
  // Update expandedSections when tableOfContents is loaded
  useEffect(() => {
    if (tableOfContents.length > 0) {
      // const initialExpanded = tableOfContents.reduce((acc: number[], item, index) => {
      //   if (item.level <= 3) {
      //     acc.push(index);
      //   }
      //   return acc;
      // }, []);
      const initialExpanded = tableOfContents.map((_, index) => index);
      setExpandedSections(initialExpanded);
    }
  }, [tableOfContents]);

  const filteredContents = useMemo(() => {
    if (!searchQuery.trim()) return tableOfContents || [];

    const query = searchQuery.toLowerCase();
    return (tableOfContents || []).filter(
      (item) =>
        item?.header?.toLowerCase().includes(query) ||
        item?.page?.toString().includes(query)
    );
  }, [searchQuery, tableOfContents]);

  const getParentIndices = (index: number) => {
    const parents: number[] = [];
    const currentItem = filteredContents[index];
    
    for (let i = index - 1; i >= 0; i--) {
      if (filteredContents[i].level < currentItem.level) {
        parents.push(i);
      }
    }
    return parents;
  };

  const hasChildren = (index: number) => {
    const currentItem = filteredContents[index];
    // Check if there are any subsequent items with a higher level
    // Only look until we find another item of the same or lower level
    for (let i = index + 1; i < filteredContents.length; i++) {
      const nextItem = filteredContents[i];
      if (nextItem.level <= currentItem.level) {
        break;
      }
      if (nextItem.level > currentItem.level) {
        return true;
      }
    }
    return false;
  };

  const toggleSection = (index: number) => {
    setExpandedSections((prev) => {
      const currentLevel = filteredContents[index].level;
      
      // Only apply collapsing logic for levels greater than 1
      if (currentLevel > 1 && prev.includes(index)) {
        // If we're closing a section, also remove all its children
        return prev.filter((i) => {
          if (i < index) return true;
          if (i === index) return false;
          return filteredContents[i].level <= currentLevel;
        });
      }
      
      // If we're opening a section or it's level 1, just add it
      return [...prev, index];
    });
  };

  const shouldShow = (index: number, item: any) => {
    if (item.level === 1) return true;
    
    // Find the immediate parent
    for (let i = index - 1; i >= 0; i--) {
      if (filteredContents[i].level < item.level) {
        return expandedSections.includes(i);
      }
    }
    return true;
  };

  const renderSkeletons = () => (
    <>
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className={cn(
            'w-full py-1 px-2 flex items-start gap-4',
            index % 3 === 0 && 'pl-4',
            index % 4 === 0 && 'pl-6'
          )}
        >
          <Skeleton className="flex-1 h-4" />
          <Skeleton className="h-4 w-8" />
        </div>
      ))}
      <div className="h-4 w-full text-center text-sm text-muted-foreground">
        Loading table of contents...
      </div>
    </>
  );
     return (
    <div className="h-full flex flex-col">
      <div className="flex-none p-4 border-b space-y-4">
        <h3 className="font-semibold">Table of Contents</h3>
        <div className="relative">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search Table of Content..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8"
            disabled={isLoadingToc}
          />
        </div>
      </div>
      <div className="flex-1 min-h-0">
        <ScrollArea className="h-full">
          <div className="p-4">
            {isLoadingToc ? (
              renderSkeletons()
            ) : filteredContents.length === 0 ? (
              <p className="text-sm text-muted-foreground text-center py-4">
                No results found for "{searchQuery}"
              </p>
            ) : (
              filteredContents.map((item, index) => {
                const isSelected = item.page === currentPage;
                const isExpanded = expandedSections.includes(index);
                const hasChildrenResult = hasChildren(index);

                if (!shouldShow(index, item)) return null;

                return (
                  <button
                    key={index}
                    onClick={() => {
                      if (hasChildrenResult) {
                        toggleSection(index);
                      } else {
                        handlePageSelect(item.page);
                      }
                    }}
                    className={cn(
                      'w-full text-left py-1 px-2 rounded-md group',
                      'text-sm transition-colors min-h-[2rem]',
                      item.level === 1 && 'font-bold text-base',
                      item.level === 2 && 'font-semibold pl-2',
                      item.level === 3 && 'pl-4',
                      item.level === 4 && 'pl-6 text-muted-foreground',
                      isSelected
                        ? 'bg-primary/10 text-primary hover:bg-primary/20'
                        : 'hover:bg-accent'
                    )}
                  >
                    <div className="flex items-start gap-4">
                      {item.level > 1 && hasChildrenResult && (
                        <ChevronRight
                          className={cn(
                            'h-4 w-4 mt-1 transition-transform',
                            isExpanded && 'transform rotate-90'
                          )}
                        />
                      )}
                      <span
                        className={cn(
                          'flex-1 line-clamp-2 leading-normal',
                          isSelected && 'text-primary'
                        )}
                      >
                        {item.header}
                      </span>
                      <span
                        className={cn(
                          'text-xs whitespace-nowrap mt-1',
                          isSelected
                            ? 'text-primary'
                            : 'text-muted-foreground group-hover:text-primary'
                        )}
                      >
                        {item.page}
                      </span>
                    </div>
                  </button>
                );
              })
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
