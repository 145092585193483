import React, { useEffect } from 'react';

const AboutTeam: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-16 bg-[var(--background)] font-['DM_Sans'] font-['Inter']">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl font-['DM_Sans'] text-[var(--primary)]  mb-12 text-center ">Our Team</h1>
        <h2 className="text-4xl font-['DM_Sans']  mb-5">Founders</h2>
        
        <div className="grid md:grid-cols-2 gap-8">
          {/* Kawal Arora */}
          <div className="bg-[var(--secondary)] p-8 rounded-[var(--radius)] shadow-sm">
            <h3 className="text-2xl font-['DM_Sans'] mb-2">Kawal Arora</h3>
            <p className="text-lg font-medium mb-1 text-[var(--primary)] font-['DM_Sans']">CEO, Vedita.AI</p>
            <p className="text-lg italic mb-4 text-[var(--muted-foreground)] font-['DM_Sans']">AI Scientist, Technologist, and Healthcare Innovator</p>
            <p className="text-gray-700 font-['DM_Sans']">
              Kawal Arora is a multifaceted AI scientist, technologist, musician, and 
              veteran of Silicon Valley. He brings a wealth of experience from the 
              healthcare technology industry, having developed medical record 
              systems and clinical trial software for prominent US clients. Kawal was 
              a key player in the Silicon Valley startup ecosystem, serving as a 
              technical evangelist and contributing to renowned companies like 
              Prosper Inc. and Tesla Motors. He is an accomplished author of 
              numerous papers and patents in AI, with a focus on finance, 
              blockchain, and healthcare. His journey into Ayurveda began after a 
              personal health crisis, leading him to embrace its healing power. Today, 
              he is dedicated to integrating his deep expertise in AI and healthcare to 
              elevate the reach and impact of Ayurveda.
            </p>
          </div>

          {/* Dr. Savita */}
          <div className="bg-[var(--secondary)] p-8 rounded-[var(--radius)] shadow-sm">
            <h3 className="text-2xl font-['DM_Sans'] mb-2">Dr. Savita</h3>
            <p className="text-lg font-medium mb-1 text-[var(--primary)] font-['DM_Sans']">CMO, Vedita.AI</p>
            <p className="text-lg italic mb-4 text-[var(--muted-foreground)] font-['DM_Sans']">Senior Ayurvedic Practitioner (BAMS, CGO, CCH)</p>
            <p className="text-gray-700 font-['DM_Sans']">
              Dr. Savita is an accomplished Ayurvedic practitioner with over 15 years 
              of clinical experience, specializing in the treatment of a wide array of 
              health conditions. She holds a BAMS degree along with certifications in 
              Gynecology & Obstetrics (CGO) and Child Health (CCH). Dr. Savita has 
              been trained under some of India's most respected master healers, 
              including Vaidya Vrushabh Hardi, Dr. Sushil Arya, Dr. Ramdas Avhad, 
              and Dr. Praveen Joshi. Her practice, spanning Belgaum, Delhi, and 
              Gurugram, has helped hundreds of patients successfully manage 
              conditions such as diabetes, thyroid disorders, kidney stones, 
              gallstones, arthritis, skin conditions, sexual health issues, and women's 
              health problems. Her deep knowledge of Ayurveda, combined with 
              hands-on experience, makes her an invaluable leader in personalized 
              Ayurvedic care.
            </p>
          </div>
        </div>
      </div>

      {/* Research Team Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16">
        <h2 className="text-4xl font-['DM_Sans']  mb-5">Research Team</h2>
        
        <div className="grid md:grid-cols-2 gap-8">
          {/* Dr. Divya Chauhan */}
          <div className="bg-[var(--secondary)] p-8 rounded-[var(--radius)] shadow-sm">
            <h3 className="text-2xl font-['DM_Sans'] mb-2">Dr. Divya Chauhan</h3>
            <p className="text-lg italic mb-4 text-[var(--muted-foreground)] font-['DM_Sans']">Ayurveda Professional, Clinical Researcher, and Medical Content Specialist</p>
            <p className="text-gray-700 font-['DM_Sans']">
              Dr. Divya Chauhan is a seasoned Ayurveda professional with a PG 
              certification in Clinical Research and Management, offering extensive 
              experience in clinical practice and medical content creation. 
              Throughout her career, she has successfully merged her deep 
              knowledge of Ayurvedic principles with a solid foundation in clinical 
              research, producing high-quality and engaging medical content. Dr. 
              Divya has collaborated with hospitals, clinics, and NGOs, including 
              NASYA and WHO, to promote Ayurveda through social and free medical 
              camps in remote areas of North India.
            </p>
          </div>

          {/* Dr. Kannan Mavila */}
          <div className="bg-[var(--secondary)] p-8 rounded-[var(--radius)] shadow-sm">
            <h3 className="text-2xl font-['DM_Sans'] mb-2">Dr. Kannan Mavila</h3>
            <p className="text-lg italic mb-4 text-[var(--muted-foreground)] font-['DM_Sans']">General Physician, Critical Care Specialist, and Holistic Healthcare Advocate</p>
            <p className="text-gray-700 font-['DM_Sans']">
              Dr. Kannan Mavila is a seasoned General Physician with specialized 
              training in Critical Care Management. He is passionate about 
              integrating new-age methods and technologies to deliver holistic 
              healthcare to patients. A strong advocate for equitable healthcare, Dr. 
              Mavila is particularly invested in ensuring well-being during end-of-life 
              care. He is driven by a mission to improve the management of care for 
              predictable health outcomes and is committed to being part of an 
              organization that prioritizes patient-centered, holistic health practices.
            </p>
          </div>
        </div>
      </div>

      {/* Advisors Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16">
        <h2 className="text-4xl font-['DM_Sans']  mb-5">Advisors</h2>
        
        <div className="space-y-8">
          {/* Dr. Manoj Kumar */}
          <div className="bg-[var(--secondary)] p-8 rounded-[var(--radius)] shadow-sm">
            <h3 className="text-2xl font-['DM_Sans'] mb-4">Dr. Manoj Kumar</h3>
            <p className="text-gray-700 font-['DM_Sans']">
              He is a renowned orthopedic surgeon and educator with over 36 years of experience teaching at leading medical institutions in India. He has 
              served as Senior Resident at JIPMER, Professor at GTB Hospital, and Director Professor at Maulana Azad Medical College. Currently, Dr. Kumar is a Professor 
              at the All India Institute of Ayurveda, specializing in integrative orthopedics and sports injuries. He has pioneered innovative surgical techniques and is 
              actively involved in research bridging Ayurveda and modern medicine. His extensive medical background and innovative healthcare solutions enhance our 
              holistic approach to health.
            </p>
          </div>

          {/* Mr. Vijay Chandra */}
          <div className="bg-[var(--secondary)] p-8 rounded-[var(--radius)] shadow-sm">
            <h3 className="text-2xl font-['DM_Sans'] mb-4">Mr. Vijay Chandra</h3>
            <p className="text-gray-700 font-['DM_Sans']">
              <span className="font-medium">CEO of Plumb5</span>, brings extensive expertise in marketing and sales. Having worked as a business leader for global companies like Xerox 
              and ICI, he has led sales and strategy, consistently delivering exceptional customer experiences. His expertise in creating exceptional customer experiences 
              and his strategic insights are instrumental in driving our marketing and sales efforts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTeam;
