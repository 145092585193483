import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Calendar,
  CheckCircle2,
  ClipboardList,
  Clock,
  FileText,
  PlayCircle,
  Stethoscope,
  User2
} from "lucide-react";
import { TestStatus } from "@/components/patient/test-status";
import { useDashboard } from "./useDashboard";
import PatientLayout from "@/layouts/Patient/PatientLayout";
import withLayout from "@/hoc/withLayout";
import { useAuth } from "@/contexts/AuthContext";
import { Skeleton } from "@/components/ui/skeleton";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useMediaQuery } from '@/hooks/use-media-query'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { tooltipStyles } from "@/constants";

function PatientDashboard() {
  const { data, isLoading, isError } = useDashboard();

  const { user } = useAuth();
  const isMobile = useMediaQuery("(max-width: 640px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");


  if (isLoading) {
    return (
      <div className="container mx-auto p-4 space-y-6 mt-8">
        <div className="space-y-6">
          {/* Welcome text skeletons */}
          <div className="flex flex-col gap-4">
            <Skeleton variant="title" className="h-10 w-48" />
            <Skeleton variant="title" className="h-10 w-64" />
          </div>

          {/* Test Status skeletons */}
          <div className="grid gap-6">
            <Card className="hover:shadow-lg transition-shadow duration-300">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 px-6 pt-6">
                <Skeleton variant="title" className="h-6 w-24" />
                <Skeleton variant="content" className="h-4 w-4" />
              </CardHeader>
              <CardContent className="px-6 pb-6">
                <div className="flex justify-between mb-2">
                  <Skeleton variant="content" className="h-4 w-16" />
                  <Skeleton variant="content" className="h-4 w-20" />
                </div>
                <Skeleton variant="content" className="h-2 w-full mt-2" />
                <Skeleton variant="content" className="h-4 w-32 mt-2" />
              </CardContent>
            </Card>
          </div>

          {/* Incomplete Tests skeleton */}
          <Card className="col-span-full md:col-span-1">
            <CardContent className="p-6">
              <div className="flex items-center justify-between mb-4">
                <Skeleton variant="title" className="h-6 w-32" />
                <Skeleton variant="content" className="h-6 w-6 rounded-full" />
              </div>
              <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="flex justify-between items-start">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <Skeleton variant="content" className="h-4 w-4" />
                        <Skeleton variant="title" className="h-5 w-48" />
                      </div>
                      <div className="flex items-center gap-2">
                        <Skeleton variant="content" className="h-2 w-24" />
                        <Skeleton variant="content" className="h-4 w-16" />
                      </div>
                      <div className="flex items-center gap-4">
                        <Skeleton variant="content" className="h-4 w-32" />
                        <Skeleton variant="content" className="h-4 w-24" />
                      </div>
                    </div>
                    <Skeleton variant="content" className="h-8 w-24 rounded-full" />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* Recent Tests skeleton */}
          <Card className="col-span-full md:col-span-1">
            <CardContent className="p-6">
              <div className="flex items-center justify-between mb-4">
                <Skeleton variant="title" className="h-6 w-32" />
                <Skeleton variant="content" className="h-6 w-6 rounded-full" />
              </div>
              <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="flex justify-between items-start">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <Skeleton variant="content" className="h-4 w-4" />
                        <Skeleton variant="title" className="h-5 w-48" />
                      </div>
                      <div className="flex items-center gap-4">
                        <Skeleton variant="content" className="h-4 w-32" />
                        <Skeleton variant="content" className="h-4 w-24" />
                      </div>
                    </div>
                    <Skeleton variant="content" className="h-8 w-24 rounded-full" />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="container mx-auto p-4 space-y-6 mt-8">
        <div className="text-center py-8">
          <h2 className="text-2xl font-semibold text-red-600">Error loading dashboard</h2>
          <p className="text-gray-600 mt-2">Please try refreshing the page</p>
        </div>
      </div>
    );
  }

  if (!data) {
    <div className="flex flex-col md:flex-col justify-between  gap-4">
      <h2 className="text-3xl font-bold">Welcome back!</h2>
      <h1 className="text-3xl font-bold">{user?.name && user.name.charAt(0).toUpperCase() + user.name.slice(1)}</h1>
    </div>
    return null;
  }

  return (
    <div className="container mx-auto p-4 space-y-6 mt-8">
      <div className="flex flex-col md:flex-col justify-between  gap-4">
        <h2 className="text-3xl font-bold">Welcome back!</h2>
        <h1 className="text-3xl font-bold">{user?.name && user.name.charAt(0).toUpperCase() + user.name.slice(1)}</h1>
      </div>

      <div className="grid gap-6">
        <TestStatus
          totalTests={data.stats.totalTests}
          completedTests={data.stats.completedTests}
          inProgressTests={data.stats.inProgressTests}
        />
      </div>

      <div className="grid gap-6 md:grid-cols-1">
        <Card className="col-span-full md:col-span-1 hover:shadow-lg transition-shadow duration-300 border-l-4 border-l-yellow-400">
          <CardContent className="p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <div className="p-2 bg-yellow-100 rounded-lg">
                  <ClipboardList className="w-5 h-5 text-yellow-700" />
                </div>
                <h2 className="text-xl font-bold">Incomplete Tests</h2>
              </div>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Clock className="w-4 h-4" />
                <span>In Progress</span>
              </div>
            </div>
            {isMobile || isTablet ? (
              <div className="space-y-4">
                {data?.activeAssessments?.map((assessment) => (
                  <Card key={assessment.id} className="p-4 border-l-4 border-l-yellow-400 hover:shadow-md transition-all">
                    <div className="flex justify-between items-start">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <FileText className="w-4 h-4 text-yellow-600" />
                          <h3 className="font-medium">{assessment.name}</h3>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="h-2 w-24 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-yellow-400 transition-all duration-300"
                              style={{ width: `${assessment?.progress ?? 0}%` }}
                            />
                          </div>
                          <p className="text-sm text-muted-foreground">{assessment?.progress ?? 0}% Complete</p>
                        </div>
                        <div className="flex items-center gap-4">
                          <span className="text-xs px-2 py-1 rounded-full bg-yellow-100 text-yellow-800 flex items-center gap-1 w-fit">
                            <Clock className="w-3 h-3" />
                            {assessment.status}
                          </span>
                          <div className="flex items-center gap-1 text-sm text-muted-foreground">
                            <Calendar className="w-4 h-4" />
                            <span>Started: {new Date(assessment.startDate).toLocaleDateString()}</span>
                          </div>
                        </div>
                      </div>
                      <Link to={`/patient/test/resume/${assessment.id}`}>
                        <Button
                          variant="outline"
                          size="sm"
                          className="rounded-full hover:bg-yellow-50 border-yellow-200 text-yellow-700 flex items-center gap-1"
                        >
                          <PlayCircle className="w-4 h-4" />
                          Resume Test
                        </Button>
                      </Link>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Test Name</TableHead>
                    <TableHead>Progress</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Started</TableHead>
                    <TableHead>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.activeAssessments?.map((assessment) => (
                    <TableRow key={assessment.id} className="hover:bg-yellow-50/50">
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <FileText className="w-4 h-4 text-yellow-600" />
                          <span className="font-medium">{assessment.name}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <div className="h-2 w-24 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-yellow-400 transition-all duration-300"
                              style={{ width: `${assessment?.progress ?? 0}%` }}
                            />
                          </div>
                          <span>{assessment?.progress ?? 0}%</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="text-xs px-2 py-1 rounded-full bg-yellow-100 text-yellow-800 flex items-center gap-1 w-fit">
                          <Clock className="w-3 h-3" />
                          {assessment.status}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-1">
                          <Calendar className="w-4 h-4 text-muted-foreground" />
                          {new Date(assessment.startDate).toLocaleDateString()}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Link to={`/patient/test/resume/${assessment.id}`}>
                          <Button
                            variant="outline"
                            size="sm"
                            className="rounded-full hover:bg-yellow-50 border-yellow-200 text-yellow-700 flex items-center gap-1"
                          >
                            <PlayCircle className="w-4 h-4" />
                            Resume Test
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-full md:col-span-1 hover:shadow-lg transition-shadow duration-300 border-l-4 border-l-green-400">
          <CardContent className="p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <div className="p-2 bg-green-100 rounded-lg">
                  <Stethoscope className="w-5 h-5 text-green-700" />
                </div>
                <h2 className="text-xl font-bold">Recent Tests</h2>
              </div>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <CheckCircle2 className="w-4 h-4" />
                <span>Completed</span>
              </div>
            </div>
            {isMobile || isTablet ? (
              <div className="space-y-4">
                {data?.recentOrders?.map((order) => (
                  <Card key={order.id} className="p-4 border-l-4 border-l-green-400 hover:shadow-md transition-all">
                    <div className="flex justify-between items-start">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <FileText className="w-4 h-4 text-green-600" />
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <h3 className="font-medium">
                                  {order.testNames.split(',')[0]}
                                  {order.testNames.split(',').length > 1 && (
                                    <span className="ml-1 text-sm text-muted-foreground">
                                      +{order.testNames.split(',').length - 1}
                                    </span>
                                  )}
                                </h3>
                              </TooltipTrigger>
                              <TooltipContent className={tooltipStyles} side="top">
                                <p>{order.testNames}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                        <div className="flex items-center gap-4">
                          <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-800 flex items-center gap-1 w-fit">
                            <CheckCircle2 className="w-3 h-3" />
                            {order.status}
                          </span>
                          <div className="flex items-center gap-1 text-sm text-muted-foreground">
                            <User2 className="w-4 h-4" />
                            <p>{ order.doctorName}</p>
                          </div>
                          <div className="flex items-center gap-1 text-sm text-muted-foreground">
                            <Calendar className="w-4 h-4" />
                            <span>{new Date(order.orderedAt).toLocaleDateString()}</span>
                          </div>
                        </div>
                      </div>
                      <Link to={`/patient/orderDetails/${order.id}`}>
                        <Button
                          variant="outline"
                          size="sm"
                          className="rounded-full hover:bg-green-50 border-green-200 text-green-700 flex items-center gap-1"
                        >
                          View Details
                        </Button>
                      </Link>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Test Name</TableHead>
                    <TableHead>Doctor</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Ordered</TableHead>
                    <TableHead>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.recentOrders?.map((order) => (
                    <TableRow key={order.id} className="hover:bg-green-50/50">
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <FileText className="w-4 h-4 text-green-600" />
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <span className="font-medium">
                                  {order.testNames.split(',')[0]}
                                  {order.testNames.split(',').length > 1 && (
                                    <span className="ml-1 text-sm text-muted-foreground">
                                      +{order.testNames.split(',').length - 1}
                                    </span>
                                  )}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent className={tooltipStyles} side="top">
                                <p>{order.testNames}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <User2 className="w-4 h-4 text-muted-foreground" />
                          <p>{order.doctorName}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-800 flex items-center gap-1 w-fit">
                          <CheckCircle2 className="w-3 h-3" />
                          {order.status}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-1">
                          <Calendar className="w-4 h-4 text-muted-foreground" />
                          {new Date(order.orderedAt).toLocaleDateString()}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Link to={`/patient/orderDetails/${order.id}`}>
                          <Button
                            variant="outline"
                            size="sm"
                            className="rounded-full hover:bg-green-50 border-green-200 text-green-700 flex items-center gap-1"
                          >
                            View Details
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default withLayout(PatientLayout)(PatientDashboard);