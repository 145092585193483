import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from 'sonner';
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Login from "./pages/Login/Login";
import Patients from "./pages/Doctor/Patients/Patients";
import Analysis from "./pages/Doctor/Analysis/Analysis";
import Register from './pages/Register/Register';
import Tests from "./pages/Doctor/Tests/Tests";
import Dashboard from "./pages/Doctor/Dashboard/Dashboard";
import Profile from "./pages/Doctor/Profile/Profile";
import PatientDashboard from "./pages/Patient/Dashboard/Dashboard";
import TestsPage from "./pages/Patient/Tests/Tests";
import OrderDetailsPage from "./pages/Patient/Orders/OrderDetailsPage";
import AssessmentPage from "./pages/Patient/Assessment/AssessmentPage";
import QuestionDetails from "./pages/Patient/Questions/QuestionDetails";
import { Library } from '@/pages/Library/Library';
import { BookDetails } from '@/pages/BookDetails/BookDetails';
import { MyLibrary } from '@/pages/MyLibrary/MyLibrary';
import { BookReader } from '@/pages/BookReader/BookReader';
import {TestResultsPage } from '@/pages/TestResults/TestResults';
import NewLandingPage from "./pages/LandingPage2/LandingPage";
import PrivacyPolicy from "./pages/LandingPage2/PrivacyPolicy/privacyPolicy";
import TermsOfUse from "./pages/LandingPage2/TermsOfUse/termsOfUse";
import CookiePolicy from "./pages/LandingPage2/CookiePolicy/cookiePolicy";
import RefundAndCancellation from "./pages/LandingPage2/RefundAndCancellation/RefundAndCancellation";
import AdminDashboard from "./pages/Admin/Dashboard/Dashboard";
import DoctorDetails from "./pages/Admin/DoctorDetails/DoctorDetails";
import GeneralProfile from "./pages/Patient/Profile/Profile";
import ContactUs from "./pages/LandingPage2/ContactUs/ContactUs";
import  TongueAnalysis  from "./pages/TongueAnalysis/TongueAnalysis";
import AboutTeam from "./pages/LandingPage2/AboutTeam/AboutTeam";
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<NewLandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/our-team" element={<AboutTeam />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/refund-and-cancellation" element={<RefundAndCancellation />} />
          {/* Doctor Routes */}
          <Route path="/doctor">
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="patients" element={<Patients />} />
            <Route path="test" element={<Tests />} />
            <Route path="profile" element={<Profile />} />
            <Route path="analysis/:patientId" element={<Analysis />} />
          </Route>

          {/* Patient Routes */}
          <Route path="/patient">
            <Route path="dashboard" element={<PatientDashboard />} />
            <Route path="test" element={<TestsPage />} />
            <Route path="orderDetails/:orderId" element={<OrderDetailsPage />} />
            <Route path="test/assessment/:assessmentId" element={<AssessmentPage />} />
            <Route path="test/resume/:assessmentId" element={<QuestionDetails />} />
            
          <Route path="tongue-analysis/:assessmentId" element={<TongueAnalysis />} />
            <Route path="profile" element={<GeneralProfile />} />
          </Route>


          <Route path="/general">
          <Route path="profile" element={<GeneralProfile />} />
          </Route>

          <Route path="/admin">
            <Route path="dashboard" element={<AdminDashboard/>} />
            <Route path="doctorsDetails/:id" element={<DoctorDetails />} />
          </Route>
          {/* Book Routes */}
          <Route path="test-results/:assessmentId/:testType/:gender" element={<TestResultsPage />} />
          <Route path="/library" element={<Library />} />
          <Route path="/my-library" element={<MyLibrary />} />
          <Route path="/books/:bookId" element={<BookDetails />} />
          <Route path="/reader/:bookId" element={<BookReader />} />
          <Route path="*" element={<PageNotFound />} />

        </Routes>
        <Toaster position="top-right" />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
