import { LOGO_URL } from '@/constants';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="relative py-8 overflow-hidden bg-white">
            {/* Background Image */}
            <div className="absolute inset-0 z-0" />

            <div className="container mx-auto px-4 max-w-5xl relative z-10">
                <div className="flex flex-col md:flex-row w-full gap-8 md:gap-4 items-center">
                    {/* Company Info */}
                    <div className="w-full md:w-[30%] space-y-4 text-center md:text-left">
                        <div className="flex justify-center md:justify-start">
                        <img src={require('../../assets/logo.png')} alt="VEDITA.AI" className="w-30 h-20" />
                        </div>
                        <div>
                            <h3 className="font-bold text-gray-800 text-sm">DESI LLM PRIVATE LIMITED</h3>
                            <address className="text-gray-600 not-italic mt-2 text-xs">
                                CTS NO. 93, KRISHNAI<br />
                                BUILDING, RAGHUVENDARA<br />
                                SOCIETY VINAYAK NAGAR,<br />
                                BELAGAVI, KARNATAKA<br />
                                591108
                            </address>
                        </div>
                    </div>

                    <div className="w-full md:w-[70%] flex items-center">
                        <nav className="w-full">
                            <ul className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
                                <li className="text-center">
                                    <Link 
                                        target='_blank'
                                        to="/privacy-policy" 
                                        className="text-gray-700 hover:text-gray-900 text-sm sm:text-base font-medium cursor-pointer relative inline-block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gray-900 after:transform after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className="text-center">
                                    <Link 
                                        target='_blank'
                                        to="/terms-of-use" 
                                        className="text-gray-700 hover:text-gray-900 text-sm sm:text-base font-medium cursor-pointer relative inline-block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gray-900 after:transform after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                                    >
                                        Terms Of Use
                                    </Link>
                                </li>
                                <li className="text-center">
                                    <Link 
                                        target='_blank'
                                        to="/cookie-policy" 
                                        className="text-gray-700 hover:text-gray-900 text-sm sm:text-base font-medium cursor-pointer relative inline-block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gray-900 after:transform after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                                    >
                                        Cookie Policy
                                    </Link>
                                </li>
                                <li className="text-center">
                                    <Link 
                                        target='_blank'
                                        to="/refund-and-cancellation"
                                        className="text-gray-700 hover:text-gray-900 text-sm sm:text-base font-medium cursor-pointer relative inline-block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gray-900 after:transform after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                                    >
                                        Refund And Cancellation
                                    </Link>
                                </li>
                                <li className="text-center">
                                    <Link 
                                    
                                        to="/contact-us"
                                        className="text-gray-700 hover:text-gray-900 text-sm sm:text-base font-medium cursor-pointer relative inline-block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gray-900 after:transform after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                                    >
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="text-center">
                                    <Link 
                                        to="/our-team"
                                        className="text-gray-700 hover:text-gray-900 text-sm sm:text-base font-medium cursor-pointer relative inline-block after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gray-900 after:transform after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                                    >
                                        Our Team
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                {/* Copyright */}
                <div className="text-center mt-8 pt-4 border-t border-gray-200">
                    <p className="text-gray-600 text-xs sm:text-sm">
                        &copy; {new Date().getFullYear()} DESI LLM PRIVATE LIMITED. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
